var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sortedNotes.length > 0
    ? _c(
        "div",
        { staticClass: "mt-3" },
        _vm._l(_vm.sortedNotes, function(note) {
          return _c("div", { key: note.id, staticClass: "note-item" }, [
            _c("div", { staticClass: "py-2 pl-2" }, [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("#" + _vm._s(note.round_num + 1))
              ]),
              _c("span", { staticClass: "font-weight-bold ml-3" }, [
                _vm._v(" " + _vm._s(_vm.displayTime(note.round_millis)) + " ")
              ]),
              _c("div", [_vm._v(" " + _vm._s(note.text) + " ")]),
              _c(
                "div",
                { staticClass: "tags" },
                _vm._l(note.tags, function(tag) {
                  return _c("b-badge", { key: tag.id, staticClass: "mr-2" }, [
                    _vm._v(_vm._s(tag.name))
                  ])
                }),
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "pr-2" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-sm btn-outline-primary",
                    attrs: { to: _vm.mapPath(note) }
                  },
                  [
                    _c("span", { staticClass: "tabe__btn-value" }, [
                      _vm._v("2D Map")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        }),
        0
      )
    : _c("div", { staticClass: "text-center mt-3" }, [_vm._v("No data")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }